// @ngInject
function acpLogoutDialogComponentCtrl($scope, acpCoreDispatcher) {
  $scope.confirm = function() {
    acpCoreDispatcher.routing.logoutDialog.confirm.emit();
  };

  $scope.cancel = function() {
    acpCoreDispatcher.routing.logoutDialog.cancel.emit();
  };
}

export default acpLogoutDialogComponentCtrl;
