import acpLogOutDialogTemplate from './templates/acp-log-out-dialog.html';
// @ngInject
function acpLogOutDialogDirective() {
  return {
    controller: 'acpLogoutDialogComponentCtrl',
    restrict: 'E',
    scope: {},
    template: acpLogOutDialogTemplate
  };
}

export default acpLogOutDialogDirective;
