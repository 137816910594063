import logoutDialogContent from './logout-dialog-content.yml';
import acpLogOutDialogCtrl from './acp-log-out-dialog-ctrl';
import acpLogOutDialogDirective from './acp-log-out-dialog-directive';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';

/**
 * @acpdoc component
 * @ngdoc module
 * @name acp.component.logout-dialog
 *
 * @requires acp.core
 *
 * @description
 *
 * @example
 * ```html
 * <acp-log-out-dialog></acp-log-out-dialog>
 * ```
 *
 * ```js
 * angular.module('acp.component.logout-dialog.example', [
 *   require('acp.component.logout-dialog').name
 * ]);
 * ```
 */
var component = ng
  .module('acp.component.logout-dialog', [acpCore.name])
  .directive('acpLogoutDialog', acpLogOutDialogDirective)
  .controller('acpLogoutDialogComponentCtrl', acpLogOutDialogCtrl)
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put('components/logout-dialog', logoutDialogContent);
    }
  );

export default component;
export {
  default as acpLogOutDialogTemplate
} from './templates/acp-log-out-dialog.html';
